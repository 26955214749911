import { authHeader, del, get, patch, post, put } from './api'

import { CandidatesPageProps } from '@store/candidates'
import {
	CandidateChanges,
	CandidateTimeSelectionStatus,
	IAccount,
	IAvailability,
	ICandidate,
	ICandidateEvent,
	ICandidateTimeSelection,
	ICandidateView,
	IPosition,
	IPositionCandidate,
	IPositionCandidateEvent,
	ISequenceInstance,
	ITentativeCandidate,
	ITimeSelection,
	PageApiResponse,
	Query,
	SequenceInstanceStatus,
	candidates,
} from '@touchpoints/requests'
import type { ContactOutPeopleSearchProfile } from '@touchpoints/contactout'

type CandidateProps = CandidateChanges

export type AddCandidateToSequenceOptions = candidates.AddCandidateToSequenceOptions

export async function fetchCandidates(orgId: string, props: CandidatesPageProps) {
	const headers = await authHeader()
	const filter = props.filter
	let url = `/organizations/${orgId}/candidates/search?page=${props.page}&limit=${props.rows}`

	if (filter.searchTerm) {
		url += `&term=${encodeURIComponent(filter.searchTerm)}`
	}

	return post<{
		result: PageApiResponse<ICandidate>
		events: (ICandidateEvent | IPositionCandidateEvent)[]
		positionCandidates: IPositionCandidate[]
	}>(url, { query: JSON.stringify(filter.query) }, headers)
}

export async function fetchCandidateSequenceInstances(
	orgId: string,
	candidateId: string,
	params?: { status?: SequenceInstanceStatus }
) {
	const headers = await authHeader()
	return get<{ sequenceInstances: ISequenceInstance[] }>(
		`/organizations/${orgId}/candidates/${candidateId}/sequence-instances`,
		params,
		headers
	)
}

export async function fetchCandidate(candidateId: string) {
	const headers = await authHeader()
	return get<{ candidate: ICandidate }>(`/candidates/${candidateId}`, undefined, headers)
}

export async function fetchCandidateWithEmail(email: string, orgSlug: string) {
	const headers = await authHeader()
	return get<{ candidate: ICandidate }>(`/candidates/e/${email}`, { o: orgSlug }, headers)
}

export async function searchCandidates(
	orgId: string,
	opts: { accountId?: string; timeSelectionStatus?: CandidateTimeSelectionStatus } = {}
) {
	const headers = await authHeader()
	return get<{
		candidates: ICandidate[]
		positionCandidates: IPositionCandidate[]
		candidateSelections: ICandidateTimeSelection[]
	}>(`/organizations/${orgId}/candidates/search`, { ...opts }, headers)
}

export async function createSelfSourcedCandidate(
	orgSlug: string,
	data: { candidate: Partial<ICandidate>; sourcerSlug: string; positionId?: string }
) {
	const headers = await authHeader()
	return post<{ candidate: ICandidate }>(
		`/organizations/${orgSlug}/candidates/self-sourced`,
		{
			candidate: data.candidate,
			sourcerSlug: data.sourcerSlug,
			positionId: data.positionId,
		},
		headers
	)
}

export async function createCandidate(
	orgId: string,
	props: CandidateProps & { addToPositionId?: string; stageId?: string }
) {
	const headers = await authHeader()
	return post<{ candidate: ICandidate; positionCandidate?: IPositionCandidate }>(
		`/organizations/${orgId}/candidates`,
		{
			...props,
		},
		headers
	)
}

export async function updateCandidate(
	orgId: string,
	candidateId: string,
	changes: Partial<CandidateProps>
) {
	const headers = await authHeader()
	return patch<{ candidate: ICandidate }>(
		`/organizations/${orgId}/candidates/${candidateId}`,
		{
			...changes,
		},
		headers
	)
}

export async function removeCandidate(orgId: string, candidateId: string) {
	const headers = await authHeader()
	return del<{ candidate: ICandidate }>(
		`/organizations/${orgId}/candidates/${candidateId}`,
		headers
	)
}

export const addCandidateToSequence = candidates.makeAddCandidateToSequence(post, authHeader)

export async function removeCandidateFromSequence(
	orgId: string,
	sequenceId: string,
	candidateId: string,
	instanceId?: string
) {
	const headers = await authHeader()
	return del(
		`/organizations/${orgId}/sequences/${sequenceId}/candidates/${candidateId}`,
		headers,
		{ instanceId }
	)
}

export async function getCandidateScheduleLink(
	orgId: string,
	candidateId: string,
	positionId: string,
	scheduleId: string
) {
	const headers = await authHeader()
	return get<{ url: string }>(
		`/organizations/${orgId}/candidates/${candidateId}/position/${positionId}/schedules/${scheduleId}`,
		undefined,
		headers
	)
}

export async function getCandidateScheduleMasterLink(
	orgId: string,
	candidateId: string,
	positionId: string
) {
	const headers = await authHeader()
	return get<{ url: string }>(
		`/organizations/${orgId}/candidates/${candidateId}/position/${positionId}/master-link`,
		undefined,
		headers
	)
}

export async function addCandidateSchedule(
	orgId: string,
	candidateId: string,
	positionId: string,
	availabilityId: string
) {
	const headers = await authHeader()
	return post<{ selection: ICandidateTimeSelection }>(
		`/organizations/${orgId}/candidates/${candidateId}/position/${positionId}/availability/${availabilityId}/schedules`,
		undefined,
		headers
	)
}

export async function addCandidateNextRoundInterview(
	orgId: string,
	candidateId: string,
	positionId: string
) {
	const headers = await authHeader()
	return put<{ selection: ICandidateTimeSelection }>(
		`/organizations/${orgId}/candidates/${candidateId}/position/${positionId}/schedules/next-round`,
		undefined,
		headers
	)
}

export async function updateCandidateSchedule(
	orgId: string,
	candidateId: string,
	positionId: string,
	scheduleId: string,
	data: Partial<ICandidateTimeSelection>
) {
	const headers = await authHeader()
	return patch(
		`/organizations/${orgId}/candidates/${candidateId}/position/${positionId}/schedules/${scheduleId}`,
		data,
		headers
	)
}

export async function deleteCandidateSchedule(
	orgId: string,
	candidateId: string,
	positionId: string,
	scheduleId: string
) {
	const headers = await authHeader()
	return del<{ selection: ICandidateTimeSelection }>(
		`/organizations/${orgId}/candidates/${candidateId}/position/${positionId}/schedules/${scheduleId}`,
		undefined,
		headers
	)
}

export async function getCandidateSchedules(
	orgId: string,
	candidateId: string,
	positionId: string
) {
	const headers = await authHeader()
	return get<{ selections: ICandidateTimeSelection[] }>(
		`/organizations/${orgId}/candidates/${candidateId}/position/${positionId}/schedules`,
		undefined,
		headers
	)
}

export async function getBulkCandidateSchedules(
	orgId: string,
	candidateIds: string[],
	positionId: string
) {
	const headers = await authHeader()
	return post<{ selections: ICandidateTimeSelection[] }>(
		`/organizations/${orgId}/position/${positionId}/candidate-schedules`,
		{ candidateIds },
		headers
	)
}

export async function getSuppliedCandidateSchedules(orgId: string) {
	const headers = await authHeader()
	return get<{ selections: ICandidateTimeSelection[] }>(
		`/organizations/${orgId}/candidate-schedules/supplied`,
		undefined,
		headers
	)
}

export async function getScheduledCandidateSchedules(orgId: string) {
	const headers = await authHeader()
	return get<{ selections: ICandidateTimeSelection[] }>(
		`/organizations/${orgId}/candidate-schedules/scheduled`,
		undefined,
		headers
	)
}

export async function getCandidateSelection(id: string, query?: { pid?: string; r?: string }) {
	return get<{
		candidate: Pick<ICandidate, 'firstName' | 'lastName' | 'email'>
		selection: Omit<ICandidateTimeSelection, 'positionId' | 'candidateId'>
		availability: Omit<IAvailability, 'id' | 'positionId'>
		position: Omit<IPosition, 'id'>
		account: Omit<IAccount, 'id'>
	}>(`/candidate-schedule/${id}`, query)
}

export async function setCandidateSelection(id: string, slots: { start: Date; end: Date }[]) {
	return put(`/candidate-schedule/${id}`, {
		slots,
	})
}

export async function previewCandidateSelectionEmail(
	orgId: string,
	templateId: string,
	selection: ICandidateTimeSelection
) {
	const headers = await authHeader()
	return post<{ html: string; subject: string }>(
		`/candidate-schedule/organizations/${orgId}/templates/${templateId}/process`,
		{ selection: { ...selection } },
		headers
	)
}

export async function selectCandidateSelectionSlot(
	orgId: string,
	candidateId: string,
	positionId: string,
	scheduleId: string,
	slot: ITimeSelection
) {
	const headers = await authHeader()
	return post<{ selection: ICandidateTimeSelection }>(
		`/organizations/${orgId}/candidates/${candidateId}/position/${positionId}/schedules/${scheduleId}/select`,
		{
			slot,
		},
		headers
	)
}

export async function unselectCandidateSelectionSlot(
	orgId: string,
	candidateId: string,
	positionId: string,
	scheduleId: string
) {
	const headers = await authHeader()
	return del<{ selection: ICandidateTimeSelection }>(
		`/organizations/${orgId}/candidates/${candidateId}/position/${positionId}/schedules/${scheduleId}/select`,
		undefined,
		headers
	)
}

export async function createCandidateView(orgId: string, data: Partial<ICandidateView>) {
	const headers = await authHeader()
	return put<{ view: ICandidateView }>(
		`/organizations/${orgId}/settings/candidate-views`,
		{ view: { ...data } },
		headers
	)
}

export async function updateCandidateView(
	orgId: string,
	viewId: string,
	data: Partial<ICandidateView>
) {
	const headers = await authHeader()
	return patch<{ view: ICandidateView }>(
		`/organizations/${orgId}/settings/candidate-views/${viewId}`,
		{ view: { ...data } },
		headers
	)
}

export async function deleteCandidateView(orgId: string, viewId: string) {
	const headers = await authHeader()
	return del<{ view: ICandidateView }>(
		`/organizations/${orgId}/settings/candidate-views/${viewId}`,
		undefined,
		headers
	)
}

export async function calculateStageCounts(orgId: string, query: Query) {
	const headers = await authHeader()
	return post<{ countsByStage: Record<string, number> }>(
		`/organizations/${orgId}/candidates/stage-counts`,
		{ query: JSON.stringify(query) },
		headers
	)
}

export async function getAllDuplicateCandidates(orgId: string) {
	const headers = await authHeader()
	return get<{ duplicates: { email: string; ids: string[]; count: number }[] }>(
		`/organizations/${orgId}/duplicate-candidates`,
		undefined,
		headers
	)
}

export async function findSourcedCandidates(orgId: string, after: number, before?: number) {
	const headers = await authHeader()
	return get<{ candidates: (ICandidate & { cards: { id: string; positionId: string }[] })[] }>(
		`/organizations/${orgId}/sourcing/candidates`,
		{ after, before },
		headers
	)
}

export const findCandidateByLinkedinUrl = candidates.makeFindCandidateByLinkedinUrl(
	post,
	authHeader
)

export async function addTentativeCandidate(orgId: string, data: Partial<ITentativeCandidate>) {
	const headers = await authHeader()
	return post<{ tentativeCandidate: ITentativeCandidate }>(
		`/organizations/${orgId}/tentative-candidates`,
		{ ...data },
		headers
	)
}

export async function fetchTentativeCandidates(orgId: string, positionId: string) {
	const headers = await authHeader()
	return get<{ tentativeCandidates: ITentativeCandidate[] }>(
		`/organizations/${orgId}/tentative-candidates`,
		{ positionId },
		headers
	)
}

export async function fetchUpdatedTentativeCandidates(orgId: string, after: number) {
	const headers = await authHeader()
	return get<{ tentativeCandidates: ITentativeCandidate[]; lastUpdate: number }>(
		`/organizations/${orgId}/tentative-candidates/updates`,
		{ after },
		headers
	)
}

export async function fetchTentativeCandidate(orgId: string, id: string) {
	const headers = await authHeader()
	return get<{ tentativeCandidate: ITentativeCandidate }>(
		`/organizations/${orgId}/tentative-candidates/${id}`,
		undefined,
		headers
	)
}

export async function deleteTentativeCandidate(orgId: string, id: string) {
	const headers = await authHeader()
	return del(`/organizations/${orgId}/tentative-candidates/${id}`, headers)
}

export async function fetchPositionsWithTentativeCandidates(orgId: string, fullData = false) {
	const headers = await authHeader()
	return get<{ positions?: IPosition[]; positionIds?: string[] }>(
		`/organizations/${orgId}/tentative-candidates/positions`,
		{ fullData },
		headers
	)
}

export async function rejectTentativeCandidate(orgId: string, tentativeCandidateId: string) {
	const headers = await authHeader()
	return post<{ tentativeCandidate: ITentativeCandidate }>(
		`/organizations/${orgId}/tentative-candidates/${tentativeCandidateId}/mark-rejected`,
		undefined,
		headers
	)
}

export async function sourceTentativeCandidate(orgId: string, tentativeCandidateId: string) {
	const headers = await authHeader()
	return post<{ tentativeCandidate: ITentativeCandidate }>(
		`/organizations/${orgId}/tentative-candidates/${tentativeCandidateId}/mark-sourced`,
		undefined,
		headers
	)
}

export async function searchForTentativeCandidates(
	orgId: string,
	jobTitle: string,
	location?: string,
	keywords?: string[],
	industry?: string[],
	yoe?: string[],
	excludeCompanies?: string[]
) {
	const headers = await authHeader()
	return post<{ profiles: ContactOutPeopleSearchProfile[] }>(
		`/organizations/${orgId}/tentative-candidates/search`,
		{
			jobTitles: [jobTitle],
			locations: location ? [location] : undefined,
			keywords,
			industries: industry,
			yoe,
			excludeCompanies: excludeCompanies,
		},
		headers
	)
}
