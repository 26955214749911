import { CandidateStageColorDot } from '@components/candidates/CandidateStageColorDot'
import { rootStore } from '@store/index'

import { PropsWithChildren, useEffect, useState } from 'react'
import { HiChevronDown, HiChevronRight } from 'react-icons/hi'

import { CandidateStageTimeline } from '@components/candidates/CandidateStageTimeline'
import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { GroupByFieldsEnum, IGroupedCard, isGroupedCards } from '@hooks/cards'
import { formatTaskDate } from '@services/date'
import { orgUserDisplay, orgUserNameDisplay } from '@services/format'
import { useReaction } from '@touchpoints/mobx-hooks'
import { BoardColumEnum, ICandidate, ICandidateStage, ICard } from '@touchpoints/requests'
import { Pill } from '@touchpoints/ui'
import { useRouter } from 'next/router'
import DataTable, { Selector, TableColumn } from 'react-data-table-component'
import { ColumnSortFunction } from 'react-data-table-component/dist/DataTable/types'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from 'react-toastify'
import { CandidateEmail } from './CandidateLabel'
import { dueTimeColor } from './hooks'
import { store } from './store'
import { nameDisplayForCandidate } from './utils'
import clsx from 'clsx'
import { Tooltip } from '@nextui-org/react'

function sort(a?: string, b?: string, transformFn?: (v: string) => string) {
	if (!a) return -1
	if (!b) return 1

	if (transformFn) {
		a = transformFn(a)
		b = transformFn(b)
	}

	if (a > b) {
		return 1
	}

	if (b > a) {
		return -1
	}

	return 0
}

function userOrgSorter(fn: (u: ICard) => string, orgData: IOrgData): ColumnSortFunction<ICard> {
	return (rowA: ICard, rowB: ICard) => {
		return sort(fn(rowA), fn(rowB), (userOrgId) => {
			const userOrg = orgData.orgUsers.find((u) => u.id === userOrgId)
			return `${userOrg?.firstName} ${userOrg?.lastName}`
		})
	}
}

function userOrgSelector(fn: (u: ICard) => string, orgData: IOrgData): Selector<ICard> {
	return (row: ICard) => {
		const orgUser = orgData.orgUsers.find((u) => u.id === fn(row))
		return orgUser ? orgUserNameDisplay(orgUser) : ''
	}
}

function useColumns(fields: BoardColumEnum[], orgData: IOrgData) {
	const [columns, setColumns] = useState<TableColumn<ICard>[]>([])

	useEffect(() => {
		const cols = fields.map((field) => {
			switch (field as BoardColumEnum) {
				case 'name':
					return {
						name: 'Name',
						selector: (row: ICard) =>
							`${row.candidate.firstName} ${row.candidate.lastName}`,
						sortable: true,
					}

				case 'email':
					return {
						name: 'Email',
						selector: (row: ICard) => (
							<span
								className="p-1 rounded-md flex items-center space-x-4 text-gray-500 text-sm cursor-pointer hover:bg-white"
								onClick={async (e) => {
									e.stopPropagation()
									navigator.clipboard.writeText(row.candidate.email)
									toast.success(`Copied to clipboard!`)
								}}
							>
								{row.candidate.email}
							</span>
						),
						sortable: true,
						sortFunction: (rowA: ICard, rowB: ICard) => {
							return sort(rowA.candidate.email, rowB.candidate.email, (v) =>
								v.toLowerCase()
							)
						},
					}

				case 'substatus':
					return {
						name: 'Sub Status',
						selector: (row: ICard) => row.subStatus,
						sortable: true,
					}

				case 'stage':
					return {
						name: 'Stage',
						cell: (row: ICard) => (
							<CandidateStageTimeline
								currentStageId={row.stage}
								stageHistory={row.stageHistory}
								orgStages={orgData.stages}
							/>
						),
						grow: 1,
						sortable: true,
						sortFunction: (rowA: ICard, rowB: ICard) => {
							if (!rowA.stage) return -1
							if (!rowB.stage) return 1

							return sort(
								rowA.stage,
								rowB.stage,
								(stageId) =>
									orgData.stages.find((s) => s.id === stageId)?.name ?? ''
							)
						},
					}

				case 'phone':
					return {
						name: 'Phone',
						selector: (row: ICard) => {
							return (
								row.candidate?.phone && (
									<span
										className="p-1 rounded-md flex items-center space-x-4 text-gray-500 text-sm cursor-pointer hover:bg-white"
										onClick={async (e) => {
											e.stopPropagation()
											navigator.clipboard.writeText(row.candidate.phone || '')
											toast.success(`Copied to clipboard!`)
										}}
									>
										<p className="text-sm text-gray-500 truncate mr-2">
											{isValidPhoneNumber(row.candidate?.phone) ? (
												formatPhoneNumber(row.candidate.phone)
											) : (
												<p className="text-sm text-gray-500 truncate mr-2">
													{row.candidate.phone}
												</p>
											)}
										</p>
									</span>
								)
							)

							// <p className="text-sm text-gray-500 truncate mr-2">--</p>
						},
						sortable: true,
						sortFunction: (rowA: ICard, rowB: ICard) => {
							return sort(rowA.candidate.phone, rowB.candidate.phone)
						},
					}
				case 'recruiter':
					return {
						name: 'Recruiter',
						sortable: true,
						selector: userOrgSelector((row: ICard) => row.recruiterId || '', orgData),
						sortFunction: userOrgSorter((row: ICard) => row.recruiterId || '', orgData),
					}
				case 'engagementContact':
					return {
						name: 'Engagement Contact',
						sortable: true,
						selector: userOrgSelector(
							(row: ICard) => row.engagementContactId || '',
							orgData
						),
						sortFunction: userOrgSorter(
							(row: ICard) => row.engagementContactId || '',
							orgData
						),
					}
				case 'accountExecutive':
					return {
						name: 'Account Executive',
						sortable: true,
						selector: userOrgSelector(
							(row: ICard) => row.accountExecutiveId || '',
							orgData
						),
						sortFunction: userOrgSorter(
							(row: ICard) => row.accountExecutiveId || '',
							orgData
						),
					}
				case 'supportContact':
					return {
						name: 'Support Contact',
						sortable: true,
						selector: userOrgSelector(
							(row: ICard) => row.supportContactId || '',
							orgData
						),
						sortFunction: userOrgSorter(
							(row: ICard) => row.supportContactId || '',
							orgData
						),
					}
				case 'position':
					return {
						name: 'Position',
						selector: (row: ICard) => {
							const position = orgData.positions.find((p) => p.id === row.position.id)
							return position?.referenceName
						},
						sortable: true,
						sortFunction: (rowA: ICard, rowB: ICard) => {
							return sort(rowA.position.id, rowB.position.id, (positionId) => {
								return (
									orgData.positions.find((p) => p.id === positionId)
										?.referenceName ?? ''
								)
							})
						},
					}
				case 'nextEvent':
					return {
						name: 'Next event',
						selector: (row: ICard) => {
							const dueByColor = dueTimeColor(row.nextEvent?.date)
							return (
								row.nextEvent && (
									<div className="flex items-center space-x-2">
										<Pill size="xs" className="p-1" color={dueByColor}>
											{formatTaskDate(row.nextEvent?.date)}
										</Pill>
										<span className="text-sm text-slate-500">
											{row.nextEvent.name}
										</span>
									</div>
								)
							)
						},
					}

				case 'pastEvent':
					return {
						name: 'Past event',
						selector: (row: ICard) => {
							const dueByColor = dueTimeColor(row.pastEvent?.date)
							return (
								row.pastEvent && (
									<div className="flex items-center space-x-2">
										<Pill size="xs" className="p-1" color={dueByColor}>
											{formatTaskDate(row.pastEvent?.date)}
										</Pill>
										<span className="text-sm text-slate-500">
											{row.pastEvent.name}
										</span>
									</div>
								)
							)
						},
					}
			}
		}) as TableColumn<ICard>[]
		setColumns(cols.filter((v) => !!v))
	}, [fields, orgData])

	return { columns }
}

export interface IOrgData {
	positions: {
		id: string
		name: string
		location: string
		referenceName: string
		managerName: string
		accountManagerId: string
	}[]
	accounts: {
		id: string
		name: string
	}[]
	orgUsers: {
		id: string
		firstName: string
		lastName: string
		email: string
	}[]
	stages: ICandidateStage[]
}
export function GroupedCards({
	groupedCards,
	selectedColumns = [],
	orgData,
}: {
	groupedCards: IGroupedCard
	selectedColumns?: BoardColumEnum[]
	orgData: IOrgData
}) {
	const isPublic = useRouter().pathname.includes('board')
	const isSubgroup = groupedCards.records.some((record) => isGroupedCards(record))

	const { columns } = useColumns(selectedColumns, orgData)

	return (
		<GroupedCardsWrapper groupedCards={groupedCards} orgData={orgData}>
			<div className="flex flex-col border rounded-md divide-y">
				{isSubgroup ? (
					groupedCards.records.map((record, index) => {
						return (
							<GroupedCards
								key={`index-${index}`}
								groupedCards={record as IGroupedCard}
								selectedColumns={selectedColumns}
								orgData={orgData}
							/>
						)
					})
				) : (
					<DataTable
						columns={columns}
						data={groupedCards.records as ICard[]}
						onRowClicked={(card) =>
							store.setSelectedPositionCandidateId(card?.id ?? '')
						}
						// striped
						customStyles={{
							table: {
								style: {
									maxWidth: '100%',
								},
							},
							rows: {
								...(!isPublic &&
									({
										cursor: 'pointer',
										'&:hover': {
											backgroundColor: '#FaFaFa',
										},
									} as any)),
							},
						}}
					/>
				)}
			</div>
		</GroupedCardsWrapper>
	)
}

interface IGroupedCardsWrapperProps {
	groupedCards: IGroupedCard
	orgData: IOrgData
}
export function GroupedCardsWrapper({
	groupedCards,
	orgData,
	children,
}: PropsWithChildren<IGroupedCardsWrapperProps>) {
	const [isOpen, setIsOpen] = useState(true)

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsOpen(!isOpen)
	}

	return (
		<div className="flex flex-col space-y-1 w-full p-3">
			{groupedCards.key === 'root' ? null : (
				<div
					className="flex items-center space-x-1 px-2 py-4 rounded-md hover:cursor-pointer hover:bg-slate-50"
					onClick={handleClick}
				>
					<span>
						{isOpen ? (
							<HiChevronDown className="w-5 h-5" />
						) : (
							<HiChevronRight className="w-5 h-5" />
						)}
					</span>
					<GroupedCardsHeader groupedCards={groupedCards} orgData={orgData} />
				</div>
			)}
			{isOpen && children}
		</div>
	)
}

export function GroupedCardsHeader({
	groupedCards,
	orgData,
}: {
	groupedCards: IGroupedCard
	orgData: IOrgData
}) {
	const renderHeader = (key: GroupByFieldsEnum, value: string) => {
		switch (key) {
			case 'stage': {
				return <StageHeader stageId={value} orgData={orgData} />
			}
			case 'account': {
				return <AccountHeader accountId={value} orgData={orgData} />
			}
			case 'position': {
				return <PositionHeader positionId={value} orgData={orgData} />
			}
			case 'candidate': {
				return <CandidateHeader candidateId={value} groupedCards={groupedCards} />
			}
			case 'subStatus': {
				return <p className="text-lg">{value}</p>
			}
			case 'engagementContactId':
				return (
					<OrganizationUserHeader
						orgUserId={value}
						orgData={orgData}
						label="ENGAGEMENT CONTACT"
					/>
				)
			case 'recruiterId':
				return (
					<OrganizationUserHeader orgUserId={value} orgData={orgData} label="RECRUITER" />
				)
			case 'accountExecutiveId':
				return (
					<OrganizationUserHeader
						orgUserId={value}
						orgData={orgData}
						label="ACCOUNT EXECUTIVE"
					/>
				)
			case 'supportContactId':
				return (
					<OrganizationUserHeader
						orgUserId={value}
						orgData={orgData}
						label="SUPPORT CONTACT"
					/>
				)
		}
	}
	return renderHeader(groupedCards.key as GroupByFieldsEnum, groupedCards.value)
}

const CandidateHeader = ({
	candidateId,
	groupedCards,
}: {
	candidateId: string
	groupedCards: IGroupedCard
}) => {
	// Recursively look for the candidate inside the grouped card records
	// This is necessary because the candidate might be nested inside multiple levels of grouping
	const findCard = (groupedCards: IGroupedCard): ICard | undefined => {
		for (const record of groupedCards.records) {
			if (isGroupedCards(record)) {
				return findCard(record as IGroupedCard)
			} else {
				if ((record as ICard).candidate.id === candidateId) {
					return record as ICard
				}
			}
		}
	}

	const card = findCard(groupedCards)
	const candidate = card?.candidate
	const cardTitle = nameDisplayForCandidate(candidate as ICandidate)
	return (
		<div className="flex space-x-2 w-full items-center">
			<p className={'h-full align-super text-lg font-medium'}>{cardTitle}</p>
			<CandidateEmail email={candidate?.email} />
		</div>
	)
}

const OrganizationUserHeader = ({
	orgUserId,
	orgData,
	label,
}: {
	orgUserId: string
	orgData: IOrgData
	label?: string
}) => {
	const orgUser = orgData.orgUsers.find((u) => u.id === orgUserId)

	return (
		<div className="flex space-x-2 w-full items-center">
			<p className="text-xs text-slate-500">{label ?? 'USER'}</p>
			<OrgUserAvatar user={orgUser} size="xs" />
			<span>{orgUser ? orgUserDisplay(orgUser) : null}</span>
		</div>
	)
}

const StageHeader = ({ stageId, orgData }: { stageId: string; orgData: IOrgData }) => {
	const stage = orgData.stages.find((s) => s.id === stageId) ?? {
		name: 'Unknown',
		color: '#000000',
	}

	return (
		<div className="flex space-x-2 w-full items-center">
			<p className="text-xs text-slate-500">STAGE</p>
			<CandidateStageColorDot color={stage.color} />
			<span>{stage.name}</span>
		</div>
	)
}

const AccountHeader = ({ accountId, orgData }: { accountId: string; orgData: IOrgData }) => {
	const account = orgData.accounts.find((account) => account.id === accountId)

	return (
		<div className="flex space-x-2 w-full items-center">
			<p className="text-xs text-slate-500">ACCOUNT</p>
			<div className="text-xl font-semibold">{account?.name}</div>
		</div>
	)
}

const PositionHeader = ({ positionId, orgData }: { positionId: string; orgData: IOrgData }) => {
	const router = useRouter()
	const isPublic = router.pathname.includes('board')
	const position = useReaction(() => orgData.positions.find((p) => p.id === positionId), 100, [
		positionId,
	])
	const accountExecutive = useReaction(
		() => {
			if (!position) {
				return undefined
			}
			const aeId = position.accountManagerId
			if (!aeId) {
				return undefined
			}

			return orgData.orgUsers.find((o) => o.id === aeId)
		},
		100,
		[position]
	)

	const handleGoToPosition = (e: React.MouseEvent<HTMLSpanElement>) => {
		e.preventDefault()
		e.stopPropagation()
		router.push(`/positions/${positionId}`)
	}

	return (
		<div className="flex space-x-4 w-full h-full items-center">
			<span className="text-3xl">{position?.referenceName}</span>
			<div className="border-l h-full" />

			<div className="flex flex-col">
				<span
					className={clsx('text-xl font-semibold text-gray-600 leading-7 truncate', {
						'cursor-pointer hover:underline': !isPublic,
					})}
					onClick={(e) => (!isPublic ? handleGoToPosition(e) : null)}
				>
					{position?.name ?? 'Unknown'}
				</span>
				<div className="flex w-full items-center space-x-2 truncate">
					<div className="flex flex-initial space-x-2 text-sm text-gray-500">
						<OrgUserAvatar user={accountExecutive} size="xs" />
						<Tooltip content={'Account executive'}>
							{orgUserNameDisplay(accountExecutive)}
						</Tooltip>
					</div>
					{position?.location && (
						<>
							<div className="text-[#c1c8cd] text-xs font-medium font-['Inter']">
								•
							</div>
							<span className="text-sm font-normal text-gray-500 truncate">
								{position.location}
							</span>
						</>
					)}
					{position?.managerName && (
						<>
							<div className="text-[#c1c8cd] text-xs font-medium font-['Inter']">
								•
							</div>
							<span className="text-sm font-normal text-gray-500 line-clamp-1">
								<Tooltip content={'Manager'}>{position.managerName}</Tooltip>
							</span>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
