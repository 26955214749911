import { PropsWithChildren } from 'react'

import { rootStore } from '@store'

import { CustomFieldsForm } from '@components/CustomFields'
import { Collapse, Link, Textarea } from '@nextui-org/react'
import { useReaction } from '@touchpoints/mobx-hooks'
import type { ICandidate, ICustomField } from '@touchpoints/requests'
import { toast } from '@touchpoints/ui'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { formatPhoneNumber } from 'react-phone-number-input'
import { EmailSource } from './EmailSource'
import { SourcerInfo } from '@components/settings/SourcerInfo'

const CandidateField = ({
	fieldName,
	value,
	asLink = false,
	asTextArea = false,
	asArray = false,
	asUserOrg = false,
}: {
	fieldName: string
	value?: any
	asLink?: boolean
	asTextArea?: boolean
	asArray?: boolean
	asUserOrg?: boolean
}) => {
	const handleLinkClick = (url?: string) => {
		console.log('clieck')
		if (!url) {
			return
		}
		window.open(url, '_blank')
	}

	const getValue = (value?: any) => {
		if (!value) return 'N/A'

		if (asLink) {
			return (
				<HiOutlineExternalLink
					className="w-5 h-5 text-[#0091FF] hover:cursor-pointer"
					onClick={() => handleLinkClick(value)}
				/>
			)
		}

		if (asTextArea) {
			return (
				<Textarea
					value={value ?? ''}
					width="100%"
					minRows={1}
					maxRows={5}
					readOnly
					borderWeight="light"
					fullWidth
					css={{ border: '2px' }}
				/>
			)
		}
		if (asUserOrg) {
			const userOrg = rootStore.organizationUsers.users[value]
			if (userOrg) {
				return <SourcerInfo sourcer={userOrg} isMember={true} />
			}

			const externalSourcer =
				rootStore.organizations.activeOrganization?.sourcingSettings.sourcers?.find(
					(s) => s.id === value
				)
			if (externalSourcer) {
				return <SourcerInfo sourcer={externalSourcer} isMember={false} />
			}

			return 'N/A'
		}
		if (asArray) {
			const arr = value as unknown as string[]
			if (arr.length === 0) {
				return 'N/A'
			}
			return (
				<div className="flex items-center space-x-1">
					{arr.map((e, index) => {
						return (
							<div key={index} className="bg-slate-100 p-1 px-3 rounded-xl">
								{e}
							</div>
						)
					})}
				</div>
			)
		}

		return value
	}

	const handleCopy = async (value: any) => {
		await navigator.clipboard.writeText(value)
		toast.success(`Copied to clipboard!`)
	}

	return (
		<div className="flex space-x-6 items-start">
			<div className="p-1 w-[170px] text-[#889096]">{fieldName}</div>
			<div
				className={clsx(
					'p-1 text-[#11181C] w-full hover:bg-slate-50 rounded-md hover:cursor-pointer',
					{
						'text-[#889096]': !value || value.length === 0,
					}
				)}
				onClick={() => handleCopy(value)}
			>
				{getValue(value)}
			</div>
		</div>
	)
}

type Props = {
	candidate: Partial<ICandidate>
	readOnly?: boolean
	onFieldChange?: (field: string, val: unknown) => void
	showLastPosition?: boolean
	positionId?: string
	hideEmptyFields?: boolean
}
export const CandidateInfoViewer = observer(function ({ candidate }: PropsWithChildren<Props>) {
	const customFields = useReaction(() => rootStore.candidates.settings.customFields)

	return (
		<div className="space-y-3 w-full pl-6">
			<div className="flex flex-col space-y-2">
				<CandidateField fieldName={'First Name'} value={candidate.firstName} />
				<CandidateField fieldName={'Last Name'} value={candidate.lastName} />
				<CandidateField fieldName={'Nickname'} value={candidate.nickname} />
				<div className="flex items-center space-x-2">
					<CandidateField fieldName={'Email'} value={candidate.email} />
					<EmailSource
						candidate={candidate as ICandidate}
						email={candidate.email ?? ''}
						size="sm"
					/>
				</div>
				<CandidateField fieldName={'Skills'} value={candidate.skills} asArray />
				<CandidateField
					fieldName={'Phone'}
					value={candidate.phone ? formatPhoneNumber(candidate.phone) : candidate.phone}
				/>
				<CandidateField fieldName={'Timezone'} value={candidate.timezone} />
				<CandidateField fieldName={'Linkedin'} value={candidate.linkedinUrl} asLink />
				<CandidateField fieldName={'Recent Employer'} value={candidate.recentEmployer} />
				<CandidateField fieldName={'Recent Job Title'} value={candidate.recentJobTitle} />
				<CandidateField fieldName={'Notes'} value={candidate.notes} asTextArea />
				<CandidateField
					fieldName={'Main Contact'}
					value={candidate.mainContactId}
					asUserOrg
				/>
				<CandidateField fieldName={'Sourcer'} value={candidate.sourcer} asUserOrg />
				<Collapse
					bordered
					title="Custom fields"
					css={{
						borderRadius: '$xs',
					}}
				>
					<CustomFieldsForm
						fields={customFields.map((f) => f as ICustomField)}
						data={candidate}
						readOnly={true}
						empty={
							<div className="flex w-full justify-center items-center p-5">
								<p>
									Candidates have no custom fields.{' '}
									<Link href="/settings/candidates">Create them here.</Link>
								</p>
							</div>
						}
					/>
				</Collapse>
			</div>
		</div>
	)
})
