import { rootStore } from '@store'
import { useEffect, useState, useMemo } from 'react'
import { useReaction } from '@touchpoints/mobx-hooks'

export function usePositionCandidateById(positionCandidateId: string) {
	const orgId = useReaction(() => rootStore.organizations.activeOrganizationId)

	const initialCandidate = useMemo(
		() => rootStore.positions.getPositionCandidateById(positionCandidateId),
		[positionCandidateId]
	)
	const [positionCandidate, setPositionCandidate] = useState(initialCandidate)

	useEffect(() => {
		if (!orgId) {
			return
		}
		if (!positionCandidate && positionCandidateId) {
			rootStore.positions.fetchPositionCandidateById(positionCandidateId).then(() => {
				setPositionCandidate(
					rootStore.positions.getPositionCandidateById(positionCandidateId)
				)
			})
		}
	}, [positionCandidate, positionCandidateId, orgId])

	return positionCandidate
}

export function usePositionCandidatesByIds(positionCandidateIds: string[]) {
	const orgId = useReaction(() => rootStore.organizations.activeOrganizationId)

	const initialCandidates = useMemo(
		() =>
			positionCandidateIds
				.map((id) => rootStore.positions.getPositionCandidateById(id))
				.filter(Boolean),
		[positionCandidateIds]
	)
	const [positionCandidates, setPositionCandidates] = useState(initialCandidates)

	useEffect(() => {
		if (!orgId) {
			return
		}
		const promises = positionCandidateIds.map(async (id) => {
			if (!rootStore.positions.getPositionCandidateById(id)) {
				await rootStore.positions.fetchPositionCandidateById(id)
			}
			return rootStore.positions.getPositionCandidateById(id)
		})

		Promise.all(promises).then((candidates) => {
			setPositionCandidates(candidates.filter(Boolean))
		})
	}, [positionCandidateIds, orgId])

	return positionCandidates
}
