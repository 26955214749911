import { rootStore } from '@store/index'
import type { ICandidate, IPositionCandidate } from '@touchpoints/requests'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

function Field({ fieldName }: { fieldName: string }) {
	return <p className="text-sm text-slate-500 whitespace-nowrap">{fieldName}</p>
}

function Value({ value }: { value: string }) {
	return <p className={`px-1 text-sm font-normal text-slate-800`}>{value}</p>
}

export const CandidateCustomFields = observer(function ({
	candidate,
	positionCandidate,
}: {
	candidate?: ICandidate
	positionCandidate?: IPositionCandidate
}) {
	const [compensation, setCompensation] = useState<string | undefined>(undefined)
	const [recruiterReview, setRecruiterReview] = useState<string | undefined>(undefined)
	const [location, setLocation] = useState<string | undefined>(undefined)
	const [substatus, setSubstatus] = useState<string | undefined>(undefined)

	useEffect(() => {
		if (!candidate?.customFields) {
			setCompensation(undefined)
			setRecruiterReview(undefined)
			setLocation(undefined)
			setSubstatus(undefined)
			return
		}

		const compensationKey = rootStore.candidates.settings.customFields.find((cf) =>
			cf.name.includes('Compensation')
		)?.key
		const recruiterReviewKey = rootStore.candidates.settings.customFields.find((cf) =>
			cf.name.includes('Feedback')
		)?.key
		const locationKey = rootStore.candidates.settings.customFields.find((cf) =>
			cf.name.includes('Location')
		)?.key

		setCompensation(
			(compensationKey && (candidate.customFields[compensationKey]?.value as string)) ||
				undefined
		)
		setRecruiterReview(
			(recruiterReviewKey && (candidate.customFields[recruiterReviewKey]?.value as string)) ||
				undefined
		)
		setLocation(
			(locationKey && (candidate.customFields[locationKey]?.value as string)) || undefined
		)
		setSubstatus(positionCandidate?.subStatus || undefined)
	}, [candidate, positionCandidate])

	console.log(substatus)
	return (
		<div className="grid grid-cols-[auto_1fr] gap-x-4 gap-y-1">
			{substatus && (
				<>
					<Field fieldName="Substatus" />
					<Value value={substatus} />
				</>
			)}
			{location && (
				<>
					<Field fieldName="Location" />
					<Value value={location} />
				</>
			)}
			{compensation && (
				<>
					<Field fieldName="Compensation" />
					<Value value={compensation} />
				</>
			)}
			{recruiterReview && (
				<>
					<Field fieldName="Recruiter Review" />
					<Value value={recruiterReview} />
				</>
			)}
		</div>
	)
})
