export function TouchpointsIcon({ size = 20 }: { size: number }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width={size}
			height={size}
			viewBox="0 0 140 99"
			fill="none"
		>
			<g clipPath="url(#clip0_335_62)">
				<path
					d="M27.1937 54.054L140 0L74.1963 76.3788L47.5028 98.7074L27.1937 54.054Z"
					fill="#A8A8A8"
				/>
				<path
					d="M27.1937 54.054L140 0L74.1963 76.3788L47.5028 98.7074L27.1937 54.054Z"
					fill="url(#pattern0_335_62)"
					fillOpacity="0.1"
					// style="mix-blend-mode:overlay"
				/>
				<path
					d="M0 32.5665L27.1937 54.054L63.4204 36.6965L78.6257 29.4103L140 0L66.3607 17.1301L48.1583 21.3662L0 32.5665Z"
					fill="#F2F2F2"
				/>
				<path
					d="M0 32.5665L27.1937 54.054L63.4204 36.6965L78.6257 29.4103L140 0L66.3607 17.1301L48.1583 21.3662L0 32.5665Z"
					fill="url(#pattern1_335_62)"
					fillOpacity="0.1"
					// style="mix-blend-mode:overlay"
				/>
				<path
					d="M47.5023 98.7075L42.5235 63.4546L74.1958 76.3789L47.5023 98.7075Z"
					fill="#535353"
				/>
				<path
					d="M48.1581 21.3662L63.4202 36.6283L78.6255 29.3951L66.3605 17.1301L48.1581 21.3662Z"
					fill="#D5D5D5"
				/>
				<path
					d="M48.1581 21.3662L63.4202 36.6283L78.6255 29.3951L66.3605 17.1301L48.1581 21.3662Z"
					fill="#FF5DA4"
				/>
				<path
					d="M78.6255 29.3951V55.0694L63.4202 64.9663V36.6284L78.6255 29.3951Z"
					fill="#FF5DA4"
				/>
				<path
					d="M78.6255 29.3951V55.0694L63.4202 64.9663V36.6284L78.6255 29.3951Z"
					fill="black"
					fillOpacity="0.2"
				/>
				<path d="M140 0L92.2131 88.9697L42.524 63.4545L140 0Z" fill="#F2F2F2" />
				<path
					d="M140 0L92.2131 88.9697L42.524 63.4545L140 0Z"
					fill="url(#pattern2_335_62)"
					fillOpacity="0.31"
					// style="mix-blend-mode:soft-light"
				/>
			</g>
			<defs>
				<pattern
					id="pattern0_335_62"
					patternContentUnits="objectBoundingBox"
					width="4.92578"
					height="5.62935"
				>
					<use xlinkHref="#image0_335_62" transform="scale(0.00481033 0.00549742)" />
				</pattern>
				<pattern
					id="pattern1_335_62"
					patternContentUnits="objectBoundingBox"
					width="3.96899"
					height="10.2797"
				>
					<use xlinkHref="#image0_335_62" transform="scale(0.00387597 0.0100388)" />
				</pattern>
				<pattern
					id="pattern2_335_62"
					patternContentUnits="objectBoundingBox"
					width="5.70047"
					height="6.24549"
				>
					<use xlinkHref="#image0_335_62" transform="scale(0.00556687 0.00609911)" />
				</pattern>
				<clipPath id="clip0_335_62">
					<rect
						width="140"
						height="98.7074"
						fill="white"
						transform="matrix(-1 0 0 1 140 0)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}
