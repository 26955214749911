import type { ICallScript, IQuestion } from '@touchpoints/requests'
import { makeAutoObservable, runInAction } from 'mobx'
import type { RootStore } from './root'
import {
	addCallScript,
	deleteCallScript,
	getAllCallScripts,
	getCallScript,
	getCallScriptInstance,
	submitScriptResponse,
	updateCallScript,
} from '@requests/questions'

class CallScript implements ICallScript {
	id: string
	organizationId: string
	name: string
	createdAt: number
	updatedAt: number
	questions: string[]

	postAsComment: boolean

	constructor(data: ICallScript) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.name = data.name
		this.createdAt = data.createdAt
		this.updatedAt = data.updatedAt
		this.questions = data.questions
		this.postAsComment = data.postAsComment ?? true

		makeAutoObservable(this)
	}

	update(data: Partial<ICallScript>) {
		this.name = data.name ?? this.name
		this.createdAt = data.createdAt ?? this.createdAt
		this.updatedAt = data.updatedAt ?? this.updatedAt
		this.questions = data.questions ?? []
		this.postAsComment = data.postAsComment ?? this.postAsComment ?? false
	}
}

export class CallScriptsStore {
	private readonly root: RootStore

	readonly list: CallScript[] = []

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)
	}

	findById(id: string) {
		return this.list.find((s) => s.id === id)
	}

	async fetchCallScripts() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await getAllCallScripts(orgId)
		if (!res.success) {
			return
		}

		runInAction(() => {
			this.list.length = 0
			this.list.push(...(res.data?.scripts.map((s) => new CallScript(s)) ?? []))
		})
	}

	async fetchScript(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await getCallScript(orgId, id)
		if (!res.success) {
			return
		}

		const { script } = res.data ?? {}
		if (!script) {
			return
		}

		const existing = this.findById(script.id)
		if (!existing) {
			const s = new CallScript(script)
			this.list.push(s)
			return s
		}

		existing.update(script)
		return existing
	}

	async addScript(data: Pick<ICallScript, 'name'>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await addCallScript(orgId, data)
		if (!res.success) {
			return
		}

		const { script } = res.data ?? {}
		if (!script) {
			return
		}

		const s = new CallScript(script)
		this.list.push(s)

		return s
	}

	async saveCallScript(id: string, data: Partial<ICallScript>) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await updateCallScript(orgId, id, data)
		if (!res.success) {
			return
		}

		const { script } = res.data ?? {}
		if (!script) {
			return
		}

		const existing = this.findById(script.id)
		if (!existing) {
			const s = new CallScript(script)
			this.list.push(s)
			return s
		}

		existing.update(script)

		return existing
	}

	async removeCallScript(id: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await deleteCallScript(orgId, id)
		if (!res.success) {
			return false
		}

		const index = this.list.findIndex((s) => s.id === id)
		if (index === -1) {
			return false
		}

		this.list.splice(index, 1)

		return true
	}

	async getInstance(id: string, data: { candidateId: string; positionId: string }) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await getCallScriptInstance(orgId, id, data.positionId, data.candidateId)
		if (!res.success) {
			return
		}

		return res.data
	}

	async submitResponse(
		id: string,
		orgSlug: string,
		data: {
			candidateId: string
			positionId: string
			responses: Record<string, string>
			questions: IQuestion[]
		}
	) {
		const res = await submitScriptResponse(
			orgSlug,
			id,
			data.positionId,
			data.candidateId,
			data.questions,
			data.responses
		)

		return res.success
	}

	launchCallScript(id: string, candidateId: string, positionId: string) {
		window.open(
			`${process.env.NEXT_PUBLIC_APP_URL}/call-scripts/view/${id}?candidateId=${candidateId}&positionId=${positionId}`,
			'_blank'
		)
	}

	launchPublicScript(id: string, orgSlug: string, candidateId: string, positionId: string) {
		window.open(
			`${process.env.NEXT_PUBLIC_APP_URL}/${orgSlug}/call-scripts/view/${id}?candidateId=${candidateId}&positionId=${positionId}`,
			'_blank'
		)
	}
}
