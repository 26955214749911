import { Avatar } from '@nextui-org/react'
import Color from 'colorjs.io'
import { getAvatarText } from '@services/format'
import { ICandidate, IOrganizationUser } from '@touchpoints/requests'
import { orgUserNameDisplay } from '@services/format'
import { useReaction } from '@hooks/mobx'
import { rootStore } from '@store/index'
import { toUpper } from 'lodash'

export function calculateUserAvatarColor(
	user?: Pick<IOrganizationUser, 'firstName' | 'lastName' | 'email' | 'settings'>
) {
	if (!user) {
		return '#000000'
	}

	if (!user.settings?.favoriteColor) {
		return stringToColor(orgUserNameDisplay(user))
	}
	return user.settings.favoriteColor
}

type Props = {
	size?: 'xs' | 'sm' | 'md' | 'lg'
	email?: string
	text?: string
}

type OrgUserAvatarProps = Props & {
	user?: Pick<IOrganizationUser, 'id' | 'firstName' | 'lastName' | 'email'>
}
export function OrgUserAvatar({ user, size = 'md', text }: OrgUserAvatarProps) {
	const latestUser = useReaction(
		() => {
			if (!user) {
				return user
			}

			return {
				...(rootStore.organizationUsers.users[user.id] ?? user),
			} as Pick<IOrganizationUser, 'id' | 'firstName' | 'lastName' | 'email'>
		},
		200,
		[user?.id]
	)
	const label = text ?? getAvatarText(latestUser)
	const bgColor = calculateUserAvatarColor(latestUser)
	const textColor = getTextColor(bgColor)
	return (
		<Avatar
			size={size}
			text={label}
			textColor={textColor}
			css={{
				zIndex: 0,
				'& span.nextui-avatar-bg': { background: bgColor },
			}}
		/>
	)
}

type CandidateAvatarProps = Props & {
	candidate?: ICandidate
}
export function CandidateAvatar({ candidate, size = 'md', text }: CandidateAvatarProps) {
	const label = text ?? getAvatarText(candidate)
	const bgColor = calculateUserAvatarColor(candidate)
	const textColor = getTextColor(bgColor)
	return (
		<Avatar
			size={size}
			text={label}
			textColor={textColor}
			css={{
				zIndex: 0,
				'& span.nextui-avatar-bg': { background: bgColor },
				pointerEvents: 'none',
			}}
		/>
	)
}

export function calculateEmailAvatarColor(email?: string) {
	if (!email) {
		return '#000000'
	}

	return stringToColor(email)
}

export function EmailAvatar({ email, size = 'md' }: Props) {
	const text = toUpper(email ? email[0] : '')
	const bgColor = calculateEmailAvatarColor(email)
	const textColor = getTextColor(bgColor)
	return (
		<Avatar
			size={size}
			text={text}
			textColor={textColor}
			css={{
				zIndex: -1,
				'& span.nextui-avatar-bg': { background: bgColor },
			}}
		/>
	)
}

export function getTextColor(background: string) {
	const color = new Color(background)
	const luminance = color.luminance
	return luminance > 0.5 ? 'default' : 'white'
}

export function getTextColorCode(background: string) {
	const shade = background ? getTextColor(background) : 'default'
	return shade === 'default' ? '#000000' : '#ffffff'
}

export function hashCode(str: string) {
	if (!str) {
		return 0
	}

	// java String#hashCode
	let hash = 0
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}
	return hash
}

export function intToRGB(i: number) {
	const c = (i & 0x00ffffff).toString(16).toUpperCase()
	return '00000'.substring(0, 6 - c.length) + c
}

export function stringToColor(value: string, mode: 'hsl' | 'rgb' = 'hsl') {
	const hash = hashCode(value)

	switch (mode) {
		case 'rgb':
			return `#${intToRGB(hash)}`

		case 'hsl':
			return `hsl(${hash % 360}, 75%, 70%)`
	}
}

export function hexToRgba(hex: string, alpha: number) {
	let c: any
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('')
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]]
		}
		c = '0x' + c.join('')
		return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`
	}
	throw new Error('Bad Hex')
}

// http://www.w3.org/TR/AERT#color-contrast
export function colorContrast(hex: string) {
	let c: any

	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('')
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]]
		}
		c = '0x' + c.join('')
		const rgb: number[] = [(c >> 16) & 255, (c >> 8) & 255, c & 255]
		const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000)

		return brightness > 125 ? 'black' : 'white'
	}
}
