import { CandidateAvatar } from '@components/shared/OrgUserAvatar'
import { useCandidate } from '@hooks/candidates'
import { usePositionCandidatesByIds } from '@hooks/positionCandidates'
import { Tooltip } from '@nextui-org/react'
import { rootStore } from '@store'
import { IPositionCandidate } from '@touchpoints/service-types'
import { Pill } from '@touchpoints/ui'
import { store } from '../accounts/store'
import { NotificationsPopover } from './NotificationsPopover'

export function RecentBar() {
	const positionCandidates = usePositionCandidatesByIds(rootStore.recent.positionCandidateIds)

	return (
		<div className="absolute top-0 right-0 left-0 h-10 z-50">
			<div className="flex w-full justify-end pointer-events-auto">
				<div className="flex p-2">
					{positionCandidates.map((pc) => {
						if (!pc) {
							return null
						}
						return <RecentPositionCandidate pc={pc} key={pc.id} />
					})}
					<div className="flex items-center justify-center ml-3">
						<NotificationsPopover />
					</div>
				</div>
			</div>
		</div>
	)
}

const RecentPositionCandidate = ({ pc }: { pc: IPositionCandidate }) => {
	const candidate = useCandidate(pc.candidateId)
	const position = rootStore.positions.getPositionById(pc.positionId)

	if (!pc || !candidate) {
		return null
	}

	const handleOpenPositionCandidate = () => {
		store.setSelectedPositionCandidateId(pc.id)
	}

	return (
		<Tooltip
			content={
				<div className="flex items-center whitespace-nowrap">
					{candidate.firstName} {candidate.lastName}{' '}
					{position ? (
						<Pill size="xs" className="p-1 px-2">
							{position.referenceName}
						</Pill>
					) : (
						''
					)}
				</div>
			}
			shadow
			placement="bottomEnd"
			key={`recent-position-candidate-${pc.id}`}
			onClick={() => handleOpenPositionCandidate()}
		>
			<div className="cursor-pointer border-2 border-white hover:border-neutral-800 transition-transform duration-200 ease-in-out rounded-full shadow-md">
				<CandidateAvatar candidate={candidate} size="sm" />
			</div>
		</Tooltip>
	)
}
