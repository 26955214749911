import { observer } from 'mobx-react-lite'
import {
	HiChevronDown,
	HiChevronUp,
	HiInbox,
	HiOutlineCog,
	HiOutlineLogout,
	HiOutlinePuzzle,
	HiOutlineUserGroup,
	HiPlusCircle,
	HiSearch,
} from 'react-icons/hi'
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from 'react-icons/hi2'
import { PiGitForkThin } from 'react-icons/pi'

import { Avatar, Spacer, Text } from '@nextui-org/react'
import { rootStore } from '@store'
import { FlexStacked, Select, Select2, createSelectOnChange } from '@touchpoints/ui'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useState } from 'react'
import { HiDesktopComputer } from 'react-icons/hi'
import { MdOutlineDarkMode, MdOutlineFavoriteBorder, MdOutlineLightMode } from 'react-icons/md'
import { OrgUserAvatar } from './shared/OrgUserAvatar'

import { orgUserNameDisplay } from '@services/format'
import * as localData from '@services/localData'
import { logout } from '@services/session'
import {
	AccountsIcon,
	CandidatesIcon,
	DashboardIcon,
	MeetingsIcon,
	PositionsIcon,
	SequencesIcon,
	SnippetsIcon,
	StageTemplateIcon,
	TasksIcon,
	TemplatesIcon,
} from '@touchpoints/icons'
import { useReaction } from '@touchpoints/mobx-hooks'
import { IFavoriteBoard, sortFavoriteBoards } from '@touchpoints/requests'
import { Dropdown } from 'flowbite-react'
import { makeAutoObservable } from 'mobx'
import Image from 'next/image'
import Link from 'next/link'
import { HiOutlineDocumentText, HiOutlineFunnel } from 'react-icons/hi2'
import { VscDebug } from 'react-icons/vsc'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'

type ViewMode = 'normal' | 'cleaning'
const store = makeAutoObservable({
	viewMode: 'normal' as ViewMode,
	setViewMode(v: ViewMode) {
		this.viewMode = v
		localData.store({ viewMode: v })
	},
})
store.setViewMode((localData.get().viewMode as ViewMode) ?? 'normal')

type UserSettingsProps = {
	collapsed?: boolean
}

type MenuItemProps = {
	icon: React.ReactNode
	name: string
	route: string
	onClick?: () => void
	disabled?: boolean
	subMenu?: MenuItemProps[]
	spacer?: boolean
}
function MenuItem({ icon, name, route, disabled, subMenu }: PropsWithChildren<MenuItemProps>) {
	const router = useRouter()
	const active = router.pathname.startsWith(route)
	const hasSubMenu = !!subMenu
	const [open, setOpen] = useState<boolean | undefined>(true)

	return (
		<>
			<li>
				<Link
					href={route}
					className={clsx(
						'flex items-center justify-between p-1 text-gray-600 rounded-lg dark:text-white font-normal',
						{ 'hover:bg-gray-100 dark:hover:bg-gray-700': !disabled },
						{ 'pointer-events-none cursor-not-allowed text-slate-400': disabled },
						{
							'bg-gray-200 dark:bg-gray-800 text-gray-900 font-semibold': active,
						}
					)}
				>
					<span className="flex items-center ml-1 w-full truncate">
						{hasSubMenu ? (
							<div className="group w-5 h-5">
								<div
									className="hidden group-hover:inline-flex"
									onClick={(e) => {
										e.stopPropagation()
										e.preventDefault()
										setOpen(!open)
									}}
								>
									{open && <HiChevronDown size={18} />}
									{!open && <HiChevronUp size={18} />}
								</div>
								<div className={clsx({ 'group-hover:hidden': hasSubMenu })}>
									{icon}
								</div>
							</div>
						) : (
							<>{icon}</>
						)}
						&nbsp;&nbsp;
						{name}
					</span>
				</Link>
			</li>
			{hasSubMenu && open && (
				<div className="ml-3">
					{subMenu.map((item) => {
						return (
							<MenuItem
								key={item.route}
								icon={item.icon}
								name={item.name}
								route={item.route}
								disabled={item.disabled === true}
								subMenu={item.subMenu}
							/>
						)
					})}
				</div>
			)}
		</>
	)
}

function CollapsedMenuItem({ item }: { item: MenuItemProps }) {
	const router = useRouter()
	const active = router.pathname.startsWith(item.route)

	return (
		<li>
			<Link
				href={item.route}
				className={clsx(
					'flex items-center justify-center p-2 text-gray-600 rounded-lg dark:text-white font-normal group relative',
					{ 'hover:bg-gray-100 dark:hover:bg-gray-700': !item.disabled },
					{ 'pointer-events-none cursor-not-allowed text-slate-400': item.disabled },
					{
						'bg-gray-200 dark:bg-gray-800 text-gray-900 font-semibold': active,
					}
				)}
			>
				{item.icon}
				<span className="invisible group-hover:visible absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap z-50">
					{item.name}
				</span>
			</Link>
		</li>
	)
}

export const Sidebar = observer(function () {
	const size = useWindowSize()
	const isMobile = size.width < 768
	const isOpen = rootStore.ui.sidebar.open
	const isCollapsed = rootStore.ui.sidebar.collapsed
	const router = useRouter()

	const needsOnboarding = rootStore.onboarding.isNeeded
	const userOrg = rootStore.organizationUsers.activeUser

	const [items, setItems] = useState<MenuItemProps[]>([])
	const view = useReaction(() => store.viewMode)

	const favoriteBoards = useReaction(
		() => sortFavoriteBoards(rootStore.favoriteBoards.list),
		500,
		[]
	)

	const subscribed = useReaction(
		() => {
			if (!userOrg?.id) return []
			return favoriteBoards.filter((fb) => fb.subscribedUsers.includes(userOrg?.id))
		},
		100,
		[userOrg, favoriteBoards]
	)

	useEffect(() => {
		rootStore.favoriteBoards.fetchFavoriteBoards()
	}, [userOrg])

	useEffect(() => {
		setItems([
			...(view === 'cleaning'
				? [
						{
							route: '/inbox',
							icon: <HiInbox className="w-4 h-4" />,
							name: 'Inbox',
							disabled: needsOnboarding,
						},
				  ]
				: []),
			{ route: '/dashboard', icon: <DashboardIcon />, name: 'Dashboard' },
			{
				route: '/tasks',
				icon: <TasksIcon />,
				name: 'Tasks',
				disabled: needsOnboarding,
			},
			{
				route: '/sourcing',
				icon: <HiSearch className="w-4 h-4" />,
				name: 'Sourcing',
				disabled: needsOnboarding,
			},
			{
				route: '/candidates',
				icon: <CandidatesIcon />,
				name: 'Candidates',
				disabled: needsOnboarding,
			},
			{
				route: '/accounts',
				icon: <AccountsIcon />,
				name: 'Accounts',
				disabled: needsOnboarding,
			},
			{
				route: '/positions',
				icon: <PositionsIcon />,
				name: 'Positions',
				disabled: needsOnboarding,
			},
			{
				route: '/workflows',
				icon: <PiGitForkThin />,
				name: 'Workflows',
				disabled: needsOnboarding,
			},
			{
				route: '/stage-templates',
				icon: <StageTemplateIcon />,
				name: 'Stage templates',
				disabled: needsOnboarding,
			},
			{
				route: '/favorites',
				icon: <MdOutlineFavoriteBorder />,
				name: 'Favorites',
				disabled: needsOnboarding,
				subMenu: subscribed.map((fb: IFavoriteBoard) => {
					return {
						route: `/favorites/${fb.id}`,
						icon: fb.emojiProps && (
							<Image
								width={15}
								height={15}
								src={fb.emojiProps.emojiUrl}
								alt={fb.emojiProps.emoji}
							/>
						),
						name: fb.name,
						disabled: needsOnboarding,
					}
				}),
				spacer: true,
			},
		])
	}, [subscribed, needsOnboarding, view])

	const handleCollapseToggle = (e: React.MouseEvent) => {
		e.stopPropagation()
		rootStore.ui.sidebar.toggleCollapsed()
	}

	return (
		<aside
			className={clsx(
				{
					hidden: !isOpen && isMobile,
					'fixed w-full bg-gray-300/30 backdrop-blur-sm z-[9999]': isOpen && isMobile,
				},
				{
					'w-[200px] z-40': isOpen && !isCollapsed && !isMobile,
					'w-[64px] z-40': isOpen && isCollapsed && !isMobile,
				},
				'md:block h-full transition-all duration-300 ease-in-out'
			)}
			aria-label="Sidebar"
			onClick={() => {
				rootStore.ui.sidebar.toggle()
			}}
		>
			<div
				className={clsx(
					'bg-white flex flex-col justify-between h-full py-1 overflow-y-auto border-r border-gray-100 dark:bg-gray-800 dark:border-gray-600 transition-all duration-300 ease-in-out',
					{
						'w-[200px]': !isCollapsed,
						'w-[64px]': isCollapsed,
					}
				)}
				onClick={(e) => e.stopPropagation()}
			>
				<ul className="space-y-2 font-medium m-0 mx-3">
					<li className={clsx('mb-8 p-1 pt-3 flex', { 'justify-center': isCollapsed })}>
						{isCollapsed ? (
							<Avatar
								css={{ zIndex: 1 }}
								text={rootStore.organizations.activeOrganization?.name ?? ''}
								size="sm"
							/>
						) : (
							<OrgSwitcher />
						)}
					</li>
					{!isCollapsed && (
						<li>
							<button
								type="button"
								className="py-1.5 px-2 w-full text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
								onClick={() => rootStore.quickSearch.open()}
							>
								<div className="flex items-center justify-between">
									<span className="flex items-center text-base text-slate-300">
										<HiSearch className="mr-2 w-5 h-5" />
										Search
									</span>
									<p className="text-base text-slate-400">⌘K</p>
								</div>
							</button>
						</li>
					)}
					{isCollapsed && (
						<li>
							<button
								type="button"
								className="p-2 w-full flex justify-center text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
								onClick={() => rootStore.quickSearch.open()}
							>
								<HiSearch className="w-5 h-5" />
							</button>
						</li>
					)}
					{items.map((item, index) => {
						return (
							<div key={item.route + index}>
								{item.spacer && <Spacer y={1} />}
								{isCollapsed ? (
									<CollapsedMenuItem item={item} />
								) : (
									<MenuItem
										icon={item.icon}
										name={item.name}
										route={item.route}
										disabled={item.disabled === true}
										subMenu={item.subMenu}
									/>
								)}
							</div>
						)
					})}
				</ul>
				<div
					className={clsx('flex', {
						'flex-col': isCollapsed,
						'flex-row items-center justify-between p-2': !isCollapsed,
					})}
				>
					{!isCollapsed ? (
						<>
							<UserSettings collapsed={isCollapsed} />
							<button
								className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 group relative"
								onClick={handleCollapseToggle}
								aria-label="Collapse sidebar"
							>
								<HiOutlineChevronDoubleLeft size={18} />
								<span className="invisible group-hover:visible absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap z-50">
									Collapse sidebar
								</span>
							</button>
						</>
					) : (
						<>
							<button
								className="self-center p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 group relative"
								onClick={handleCollapseToggle}
								aria-label="Expand sidebar"
							>
								<HiOutlineChevronDoubleRight size={18} />
								<span className="invisible group-hover:visible absolute right-full mr-2 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap z-50">
									Expand sidebar
								</span>
							</button>
							<UserSettings collapsed={isCollapsed} />
						</>
					)}
				</div>
			</div>
		</aside>
	)
})

function SwitchIcon() {
	return (
		<svg
			width="8"
			height="12"
			viewBox="0 0 8 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.33334 4.00016L4.00001 1.3335L6.66668 4.00016M6.66668 8.00016L4.00001 10.6668L1.33334 8.00016"
				stroke="#889096"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const OrgSwitcher = observer(() => {
	const router = useRouter()
	const organization = rootStore.organizations.activeOrganization

	const handleSwitchOrg = (id: string) => {
		rootStore.organizations.switchTo(id)
	}

	const handleCreateOrg = async () => {
		router.push('/organizations/create')
	}

	return (
		<Dropdown
			inline
			label=""
			renderTrigger={() => (
				<div className="flex justify-between items-center w-full cursor-pointer">
					<div className="flex space-x-2 items-center">
						<Avatar css={{ zIndex: 1 }} text={organization?.name ?? ''} size="sm" />
						<p className="text-base font-medium dark:text-gray-50">
							{organization?.name}
						</p>
						<SwitchIcon />
					</div>
				</div>
			)}
		>
			{rootStore.organizations.list
				.filter((org) => org.name !== organization?.name)
				.map((org) => {
					return (
						<Dropdown.Item key={org.id} onClick={() => handleSwitchOrg(org.id)}>
							{org.name}
						</Dropdown.Item>
					)
				})}
			<Dropdown.Divider />
			<Dropdown.Item onClick={handleCreateOrg}>
				<HiPlusCircle className="mr-2 w-5 h-5" /> Create New
			</Dropdown.Item>
		</Dropdown>
	)
})

const UserSettings = observer(({ collapsed }: UserSettingsProps) => {
	const user = rootStore.users.loggedInUser
	const orgUser = rootStore.organizationUsers.activeUser
	const organization = rootStore.organizations.activeOrganization
	const router = useRouter()

	const items = [
		{
			text: 'Teams',
			onClick: () => router.push('/teams'),
			icon: <HiOutlineUserGroup />,
		},
		{
			text: 'Sequences',
			onClick: () => router.push('/sequences'),
			icon: <SequencesIcon />,
		},
		{
			text: 'Templates',
			onClick: () => router.push('/templates'),
			icon: <TemplatesIcon />,
		},
		{
			text: 'Snippets',
			onClick: () => router.push('/snippets'),
			icon: <SnippetsIcon />,
		},
		{
			text: 'Meetings',
			onClick: () => router.push('/meetings'),
			icon: <MeetingsIcon />,
		},
		{
			text: 'Funnels',
			onClick: () => router.push('/funnels'),
			icon: <HiOutlineFunnel />,
		},
		{
			text: 'Call Scripts',
			onClick: () => router.push('/call-scripts'),
			icon: <HiOutlineDocumentText />,
		},

		{
			text: 'Settings',
			onClick: () => router.push('/settings/account'),
			icon: <HiOutlineCog />,
		},
		{
			text: 'Connect Extension',
			onClick: () => router.push('/extension'),
			icon: <HiOutlinePuzzle />,
		},
		{
			text: 'Logout',
			onClick: logout,
			icon: <HiOutlineLogout />,
		},
	]

	return (
		<div className={clsx('', { 'flex justify-center': collapsed })}>
			<Dropdown
				inline
				placement="top"
				label=""
				renderTrigger={() => (
					<div
						className={clsx(
							'flex justify-between items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 p-1 rounded-full',
							{
								'w-full': !collapsed,
							}
						)}
					>
						<div className="flex space-x-2 items-center">
							{orgUser ? (
								<OrgUserAvatar user={orgUser} size="xs" />
							) : (
								<Avatar text={user?.email} />
							)}
							{!collapsed && (
								<p className="text-sm font-medium max-w-[100px] dark:text-gray-50 whitespace-nowrap truncate">
									{orgUserNameDisplay(orgUser)}
								</p>
							)}
						</div>
					</div>
				)}
			>
				<Dropdown.Header>
					<FlexStacked>
						<Text>{user?.email}</Text>
						<Text size="12px">{organization?.name}</Text>
					</FlexStacked>
				</Dropdown.Header>
				<Dropdown.Header>
					<DarkModeToggle />
				</Dropdown.Header>
				<Dropdown.Header>
					<ViewSelect />
				</Dropdown.Header>
				{items.map((item, idx) => {
					return (
						<Dropdown.Item key={`item_${idx}`} onClick={item.onClick}>
							<div className="flex items-center dark:text-gray-50">
								<span className="mr-3">{item.icon}</span>
								{item.text}
							</div>
						</Dropdown.Item>
					)
				})}
				<Dropdown.Item onClick={() => router.push('/debug')}>
					<div className="flex items-center dark:text-gray-50">
						<span className="mr-3">{<VscDebug />}</span>
						Debug
					</div>
				</Dropdown.Item>
			</Dropdown>
		</div>
	)
})

function ViewSelect() {
	const view = useReaction(() => store.viewMode)

	const options = [
		{ value: 'cleaning', label: 'Cleaning' },
		{ value: 'normal', label: 'Normal' },
	]

	return (
		<Select
			label="View"
			value={options.find((o) => o.value === view)}
			options={options}
			onChange={createSelectOnChange((v) => store.setViewMode(v as ViewMode))}
		/>
	)
}

function DarkModeToggle() {
	const ThemeLabel = ({ theme }: { theme: string }) => {
		return (
			<div className="flex items-center space-x-2">
				{theme === 'dark' && <MdOutlineDarkMode />}
				{theme === 'light' && <MdOutlineLightMode />}
				{theme === 'system' && <HiDesktopComputer />}
				&nbsp; {theme}
			</div>
		)
	}

	const [theme, setTheme] = useLocalStorage<string>('theme', 'light')
	const statusOptions = [
		{
			value: 'light',
			label: <ThemeLabel theme="light" />,
		},
		{
			value: 'dark',
			label: <ThemeLabel theme="dark" />,
		},
		{
			value: 'system',
			label: <ThemeLabel theme="system" />,
		},
	]

	return (
		<Select2
			label="Theme"
			value={statusOptions.find((s) => s.value === theme)?.label ?? 'Light'}
			options={statusOptions}
			onChange={(o) => {
				if (!o) {
					return
				}
				setTheme?.(o.value)
			}}
		/>
	)
}
