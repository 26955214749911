import { Link, Row, Spacer, Text } from '@nextui-org/react'

import { FlexStacked, Divider } from '@touchpoints/ui'
import { useRouter } from 'next/router'

export function SettingsNav() {
	const router = useRouter()
	return (
		<div className="flex flex-col p-5 w-48 h-full">
			<Row>
				<Text className="font-semibold">USER</Text>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/account')}
				>
					My Account
				</Link>
			</Row>
			<Spacer y={0.5} />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/mailboxes')}
				>
					Mailboxes
				</Link>
			</Row>
			<Spacer y={0.5} />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/calendar')}
				>
					Calendar
				</Link>
			</Row>
			<Divider />
			<Row>
				<Text className="font-semibold">COMPANY</Text>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/organization')}
				>
					Organization
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/users')}
				>
					Users
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/stages')}
				>
					Stages
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/emails')}
				>
					Emails
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/sourcing')}
				>
					Sourcing
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/cleaning')}
				>
					Cleaning
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/accounts')}
				>
					Accounts
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/positions')}
				>
					Positions
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/candidates')}
				>
					Candidates
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/webhooks')}
				>
					Webhooks
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/notifications')}
				>
					Notifications
				</Link>
			</Row>
			<Spacer />
			<Row>
				<Link
					className="cursor-pointer"
					color="text"
					onClick={() => router.push('/settings/airtable')}
				>
					Airtable
				</Link>
			</Row>
			<Spacer />
		</div>
	)
}
