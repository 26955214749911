import { AccountsIcon } from '@touchpoints/icons'
import { ISourcer } from '@touchpoints/requests'

export function SourcerInfo({ sourcer, isMember }: { sourcer: ISourcer; isMember: boolean }) {
	return (
		<div className="flex items-center gap-2">
			<p className="font-medium whitespace-nowrap">
				{sourcer.firstName} {sourcer.lastName}
			</p>
			{isMember ? (
				<span className="whitespace-nowrap inline-flex items-center rounded-md bg-green-50 px-1 text-xs/5 font-medium text-green-700 ring-1 ring-green-600/20 ring-inset">
					Organization User
				</span>
			) : (
				<span className="whitespace-nowrap inline-flex items-center rounded-md bg-red-50 px-1 text-xs/5 font-medium text-red-700 ring-1 ring-red-600/20 ring-inset">
					External Sourcer
				</span>
			)}
			{sourcer.company && (
				<div className="flex items-center gap-2 whitespace-nowrap ">
					<AccountsIcon size={16} />

					<p className="text-xs/5 text-gray-500">{sourcer.company}</p>
				</div>
			)}
		</div>
	)
}
