import { InputHTMLAttributes, PropsWithChildren, ReactNode } from 'react'
import { Label } from './Label'
import clsx from 'clsx'

export type InputProps = {
	label?: string | ReactNode
	value?: string
	readOnly?: boolean
	placeholder?: string
	onChange?: (val: string) => void
	tabIndex?: number
	noBorder?: boolean
	noPadding?: boolean
	noFocus?: boolean
	inputClassName?: string
} & InputHTMLAttributes<HTMLInputElement>

export function Input({
	value,
	onChange,
	readOnly,
	label,
	noBorder,
	noPadding,
	noFocus,
	inputClassName,
	children,
	...props
}: PropsWithChildren<InputProps>) {
	const isString = typeof label === 'string'

	return (
		<div className="w-full">
			{label && isString && <Label>{label}</Label>}
			{label && !isString && <div>{label}</div>}
			<div
				className={clsx(
					'flex items-center justify-between rounded-md w-full placeholder:text-sm placeholder:text-slate-400 hover:cursor-pointer',
					{ 'bg-slate-50': !!readOnly },
					{ border: !noBorder }
				)}
			>
				<input
					value={value ?? ''}
					onChange={(e) => onChange?.(e.currentTarget.value)}
					className={clsx(
						`border-0 rounded-md w-full`,
						{
							'py-2 px-3': !noPadding,
							'bg-slate-50 outline-none': !!readOnly,
							'focus:outline focus:outline-[#0091FF]': !readOnly && !noFocus,
						},
						inputClassName
					)}
					readOnly={readOnly}
					{...props}
				/>
				{children}
			</div>
		</div>
	)
}
