import type { RootStore } from './root'
import { fetchNotifications, markNotificationAsRead } from '../requests/notifications'
import { autorun, makeAutoObservable, runInAction } from 'mobx'
import { INotification, INotificationPayload, NotificationType } from '@touchpoints/requests'
import { chunk } from 'lodash'

class Notification implements INotification {
	id = ''
	organizationId = ''
	userId = ''
	type = NotificationType.TASK_COMPLETED
	createdAt = 0
	readAt?: number
	payload: INotificationPayload = {
		content: '',
	}

	constructor(data: INotification) {
		makeAutoObservable(this)
		this.update(data)
	}

	update(data: INotification) {
		this.id = data.id
		this.organizationId = data.organizationId
		this.userId = data.userId
		this.type = data.type
		this.readAt = data.readAt
		this.createdAt = data.createdAt
		this.payload = data.payload
	}
}

export class NotificationsStore {
	private readonly root: RootStore
	list: Notification[] = []

	get unreadCount() {
		return this.list.filter((n) => !n.readAt).length
	}

	get unread() {
		return this.list.filter((n) => !n.readAt)
	}

	constructor(root: RootStore) {
		this.root = root

		makeAutoObservable(this)

		autorun(() => {
			this.fetchNotifications()
		})
	}

	async fetchNotifications() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await fetchNotifications(orgId)
		if (!res.success) {
			return
		}

		const { notifications = [] } = res.data ?? {}

		runInAction(() => {
			this.clearNotifications()
			notifications.forEach((notification) => {
				this.addNotification(notification)
			})
		})
	}

	async markAsRead(notificationId: string) {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const res = await markNotificationAsRead(orgId, notificationId)
		if (!res.success) {
			return false
		}

		const { notification } = res.data ?? {}
		if (notification) {
			runInAction(() => {
				const existingNotification = this.getById(notification.id)
				if (existingNotification) {
					existingNotification.update(notification)
				}
			})
		}

		return true
	}

	async markAllAsRead() {
		const orgId = this.root.organizations.activeOrganizationId
		if (!orgId) {
			return
		}

		const chunkedNotifications = chunk(this.list, 10)

		for (const notifications of chunkedNotifications) {
			await Promise.all(
				notifications.map((notification) => {
					notification.readAt = new Date().getTime()
					return this.markAsRead(notification.id)
				})
			)
		}

		return true
	}

	getById(id: string) {
		return this.list.find((n) => n.id === id)
	}

	private clearNotifications() {
		this.list = []
	}

	private addNotification(notification: INotification) {
		const n = new Notification(notification)
		this.list.push(n)
		return n
	}
}
