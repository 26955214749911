import { useTaskName } from '@hooks/tasks'
import { LockClosedIcon } from '@touchpoints/icons'
import type { ITask } from '@touchpoints/requests'
import { observer } from 'mobx-react-lite'
import { HiPaperAirplane } from 'react-icons/hi'

export const TaskName = observer(function ({ task }: { task: ITask }) {
	const name = useTaskName(task)

	return (
		<div data-tag="allowRowEvents" className="relative w-full h-5">
			<span
				data-tag="allowRowEvents"
				className="flex items-center text-sm text-slate-800 dark:text-slate-300 pointer-events-none absolute top-0 left-0 bottom-0 right-0 truncate"
			>
				{task.isPrivate ? <LockClosedIcon className="mr-2" /> : null}
				{task.sequenceInstanceId ? <HiPaperAirplane className="mr-2" /> : null}
				{task.referenceNumber && (
					<p className="text-sm text-slate-500">{task.referenceNumber}&nbsp;</p>
				)}
				{name}
			</span>
		</div>
	)
})
