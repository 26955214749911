import { makeAutoObservable } from 'mobx'
import * as localData from '@services/localData'

export class SidebarStore {
	open = false
	collapsed = false

	constructor() {
		makeAutoObservable(this)
		// Load collapsed state from localStorage if available
		const savedState = localData.get()
		if (savedState.sidebarCollapsed !== undefined) {
			this.collapsed = savedState.sidebarCollapsed as boolean
		}
	}

	toggle() {
		this.open = !this.open
	}

	toggleCollapsed() {
		this.collapsed = !this.collapsed
		// Save collapsed state to localStorage
		localData.store({ sidebarCollapsed: this.collapsed })
	}
}
