import { rootStore } from '@store'
import { useEffect, useState } from 'react'
import { useReaction } from '@touchpoints/mobx-hooks'

export function useCandidate(candidateId: string) {
	const orgId = useReaction(() => rootStore.organizations.activeOrganizationId)
	const [candidate, setCandidate] = useState(() =>
		rootStore.candidates.getCandidateById(candidateId)
	)

	useEffect(() => {
		if (!orgId) {
			return
		}
		if (!candidate) {
			rootStore.candidates.getCandidateByIdAsync(candidateId).then(() => {
				setCandidate(rootStore.candidates.getCandidateById(candidateId))
			})
		}
	}, [candidate, candidateId, orgId])

	return candidate
}

export function useCandidates(candidateIds: string[]) {
	const orgId = useReaction(() => rootStore.organizations.activeOrganizationId)
	const [candidates, setCandidates] = useState(() =>
		candidateIds.map((id) => rootStore.candidates.getCandidateById(id)).filter(Boolean)
	)

	useEffect(() => {
		if (!orgId) {
			return
		}
		const promises = candidateIds.map(async (id) => {
			if (!rootStore.candidates.getCandidateById(id)) {
				await rootStore.candidates.getCandidateByIdAsync(id)
			}
			return rootStore.candidates.getCandidateById(id)
		})

		Promise.all(promises).then((results) => {
			setCandidates(results.filter(Boolean))
		})
	}, [candidateIds, orgId])

	return candidates
}
