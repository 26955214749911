export const NoPriorityIcon = ({ size }: { size: number | string | undefined }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="3" y="10" width="4" height="1" rx="0.5" fill="#889096" />
			<rect x="8" y="10" width="4" height="1" rx="0.5" fill="#889096" />
			<rect width="4" height="1" rx="0.5" transform="matrix(1 0 0 -1 13 11)" fill="#889096" />
		</svg>
	)
}
export const LowPriorityIcon = ({ size }: { size: number | string | undefined }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 14 14"
			fill="none"
		>
			<rect y="7" width="4" height="7" rx="1" fill="#687076" />
			<rect x="5" y="4" width="4" height="10" rx="1" fill="#F1F3F5" />
			<rect x="10" width="4" height="14" rx="1" fill="#F1F3F5" />
		</svg>
	)
}

export const MediumPriorityIcon = ({ size }: { size: number | string | undefined }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 14 14"
			fill="none"
		>
			<rect y="7" width="4" height="7" rx="1" fill="#687076" />
			<rect x="5" y="4" width="4" height="10" rx="1" fill="#687076" />
			<rect x="10" width="4" height="14" rx="1" fill="#F1F3F5" />
		</svg>
	)
}

export const HighPriorityIcon = ({ size }: { size: number | string | undefined }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 14 14"
			fill="none"
		>
			<rect y="7" width="4" height="7" rx="1" fill="#687076" />
			<rect x="5" y="4" width="4" height="10" rx="1" fill="#687076" />
			<rect x="10" width="4" height="14" rx="1" fill="#687076" />
		</svg>
	)
}

export const TodoIcon = ({ size = 20 }: { size?: number }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
		>
			<rect
				x="0.5"
				y="0.5"
				width="15"
				height="15"
				rx="7.5"
				fill="#F8F9FA"
				stroke="#889096"
				strokeDasharray="2 2"
			/>
		</svg>
	)
}

export const InProgressIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.00008 2.33317C5.31818 2.33317 2.33341 5.31794 2.33341 8.99984C2.33341 12.6817 5.31818 15.6665 9.00008 15.6665V2.33317ZM0.666748 8.99984C0.666748 4.39746 4.39771 0.666504 9.00008 0.666504C9.31241 0.666504 9.62102 0.683723 9.92498 0.717323C14.0925 1.17802 17.3334 4.70988 17.3334 8.99984C17.3334 13.2898 14.0925 16.8217 9.92498 17.2824C9.62102 17.316 9.3124 17.3332 9.00008 17.3332C4.39771 17.3332 0.666748 13.6022 0.666748 8.99984Z"
				fill="#AB4ABA"
			/>
		</svg>
	)
}

export const DoneIcon = ({ size = 20 }: { size?: number }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 12 12"
			fill="none"
		>
			<g clipPath="url(#clip0_196_11495)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.22846 -1.2887L5.99997 -4.47852L8.77149 -1.2887L12.945 -1.95074L13.0177 2.2743L16.6403 4.4498L13.9802 7.73312L15.3569 11.7282L11.2087 12.5335L9.69533 16.4789L5.99997 14.4294L2.30462 16.4789L0.791224 12.5335L-3.357 11.7282L-1.98029 7.73312L-4.64038 4.4498L-1.01776 2.27429L-0.945025 -1.95074L3.22846 -1.2887ZM9.91416 4.54605L8.49994 3.13184L4.99994 6.63184L3.49994 5.13184L2.08572 6.54605L4.99994 9.46027L9.91416 4.54605Z"
					fill="#0091FF"
				/>
			</g>
			<defs>
				<clipPath id="clip0_196_11495">
					<rect width="12" height="12" rx="6" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}

export const CloseIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM5.6 4.46863L4.46863 5.6L6.86863 8L4.46863 10.4L5.6 11.5314L8 9.13137L10.4 11.5314L11.5314 10.4L9.13137 8L11.5314 5.6L10.4 4.46863L8 6.86863L5.6 4.46863Z"
				fill="#889096"
			/>
		</svg>
	)
}
