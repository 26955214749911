export enum NotificationType {
	TASK_COMPLETED = 'task-completed',
	TASK_CREATED = 'task-created',
	NEW_COMMENT = 'new-comment',
	MENTIONED = 'mentioned',
}

export interface INotification {
	id: string
	type: NotificationType
	organizationId: string
	userId: string 
	createdAt: number
	readAt?: number

	payload: INotificationPayload
}

export interface INotificationPayload {
	content: string
	task?: {
		id: string
	}
	comment?: {
		id: string
		candidateId: string
		positionId: string
		positionCandidateId: string
		fromOrgUserId: string
	}
}
