import { OrgUserAvatar } from '@components/shared/OrgUserAvatar'
import { DoneIcon, TodoIcon } from '@components/tasks/icons'
import { formatTimeDifference } from '@services/date'
import { linkMentions } from '@services/mentions'
import { rootStore } from '@store/index'
import { useReaction } from '@touchpoints/mobx-hooks'
import type { INotification, TaskActivityCompletedPayload } from '@touchpoints/requests'
import { NotificationType } from '@touchpoints/requests'
import { Popover2 } from '@touchpoints/ui'
import Autolinker from 'autolinker'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { GoMegaphone } from 'react-icons/go'
import { HiChatBubbleOvalLeft } from 'react-icons/hi2'
import { useInterval } from 'usehooks-ts'
import { store } from '../accounts/store'
import { runInAction } from 'mobx'
import clsx from 'clsx'
const POLL_INTERVAL = 1000 * 60 * 1

type NotificationItemProps = {
	notification: INotification
}

export function NotificationsPopover() {
	const headerRef = useRef<HTMLHeadingElement>(null)

	const [notifications, setNotifications] = useState<INotification[]>([])

	useInterval(() => {
		rootStore.notifications.fetchNotifications()
	}, POLL_INTERVAL)

	useEffect(() => {
		setNotifications(rootStore.notifications.list)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rootStore.notifications.list])

	useEffect(() => {
		if (notifications.length === 0) {
			return
		}

		const fetchPositionCandidate = (notification: INotification) => {
			const pcId = notification.payload.comment?.positionCandidateId
			if (!pcId) {
				return
			}

			const pc = rootStore.positions.getPositionCandidateById(pcId)
			if (!pc) {
				rootStore.positions.fetchPositionCandidateById(pcId)
			}
		}
		const fetchCandidate = (notification: INotification) => {
			const candidateId = notification.payload.comment?.candidateId
			if (!candidateId) {
				return
			}

			const candidate = rootStore.candidates.getCandidateById(candidateId)
			if (!candidate) {
				rootStore.candidates.getCandidateByIdAsync(candidateId)
			}
		}
		for (const notification of notifications) {
			fetchPositionCandidate(notification)
			fetchCandidate(notification)
		}
	}, [notifications])

	const handleOpenChange = (isOpen: boolean) => {
		if (!isOpen) {
			return
		}

		setTimeout(() => {
			if (headerRef.current) {
				headerRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'end',
					inline: 'start',
				})
			}
		}, 0)
	}

	const handleMarkAllAsRead = async () => {
		setNotifications(notifications.map((n) => ({ ...n, readAt: new Date().getTime() })))
		await rootStore.notifications.markAllAsRead()
	}

	const unreadCount = notifications.filter((n) => !n.readAt).length

	return (
		<Popover2
			trigger={
				<div className="flex items-center justify-center relative mt-1 mr-4">
					<GoMegaphone className="w-7 h-7 text-neutral-800 cursor-pointer hover:text-neutral-600" />
					{unreadCount > 0 && (
						<div className="absolute -top-1 -right-1 flex items-center justify-center bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 min-w-5">
							{unreadCount > 99 ? '99+' : unreadCount}
						</div>
					)}
				</div>
			}
			placement="bottom-end"
			onOpenChange={handleOpenChange}
		>
			<div className="flex flex-col border overflow-hidden rounded-lg shadow-md shadow-neutral-200 max-h-[600px] space-y-3 mr-4">
				{notifications.length === 0 ? (
					<NoNotifications />
				) : (
					<div className="flex flex-col w-[25rem] overflow-y-auto">
						<div className="flex justify-between items-center sticky top-0 bg-white border-b border-neutral-200 z-10 p-2 py-3">
							<h2 className="text-sm font-medium text-neutral-800" ref={headerRef}>
								Notifications
							</h2>
							{unreadCount > 0 && (
								<button
									onClick={handleMarkAllAsRead}
									className="text-xs font-medium text-calendarblue-500 hover:bg-slate-50 px-2 py-1 rounded-md"
									tabIndex={-1} // Prevent auto-focus
								>
									Mark all as read
								</button>
							)}
						</div>

						<div className="flex flex-col divide-y divide-neutral-200">
							{notifications.map((notification) => (
								<NotificationItem
									key={notification.id}
									notification={notification}
								/>
							))}
						</div>
					</div>
				)}
			</div>
		</Popover2>
	)
}

function NotificationItem({ notification }: NotificationItemProps) {
	const handleMarkAsRead = async () => {
		runInAction(() => {
			notification.readAt = new Date().getTime()
		})

		if (!notification.readAt) {
			await rootStore.notifications.markAsRead(notification.id)
		}
	}

	return (
		<div
			className={clsx(`flex flex-col divide-y divide-neutral-200 bg-white`, {
				'bg-blue-50': !notification.readAt,
			})}
		>
			{(() => {
				switch (notification.type) {
					case NotificationType.NEW_COMMENT:
						return (
							<CommentNotification
								notification={notification}
								onRead={handleMarkAsRead}
							/>
						)
					case NotificationType.MENTIONED:
						return (
							<CommentNotification
								notification={notification}
								mentioned={true}
								onRead={handleMarkAsRead}
							/>
						)
					case NotificationType.TASK_CREATED:
						return (
							<TaskNotification
								notification={notification}
								onRead={handleMarkAsRead}
							/>
						)
					case NotificationType.TASK_COMPLETED:
						return (
							<TaskNotification
								notification={notification}
								completed={true}
								onRead={handleMarkAsRead}
							/>
						)
					default:
						return <UnknownNotification notification={notification} />
				}
			})()}
		</div>
	)
}

function bold(text: string | undefined) {
	if (!text) {
		return text
	}
	return <span className="font-medium text-neutral-800">{text}</span>
}

type CommentNotificationProps = {
	notification: INotification
	mentioned?: boolean
	onRead: () => void
}

function CommentNotification({ notification, mentioned, onRead }: CommentNotificationProps) {
	const user = useReaction(() => {
		const fromOrgUserId = notification.payload.comment?.fromOrgUserId
		if (!fromOrgUserId) {
			return undefined
		}
		return rootStore.organizationUsers.users[fromOrgUserId]
	}, 100)

	const position = useReaction(() => {
		return notification.payload.comment?.positionId
			? rootStore.positions.positionById[notification.payload.comment.positionId]
			: undefined
	}, 100)

	const candidate = useReaction(() => {
		return notification.payload.comment?.candidateId
			? rootStore.candidates.getCandidateById(notification.payload.comment.candidateId)
			: undefined
	}, 100)

	const positionCandidate = useReaction(() => {
		return notification.payload.comment?.positionCandidateId
			? rootStore.positions.getPositionCandidateById(
					notification.payload.comment.positionCandidateId
			  )
			: undefined
	}, 100)

	const comment = positionCandidate?.comments.find(
		(c) => c.id === notification.payload.comment?.id
	)

	const createdAt = new Date(notification.createdAt)

	const handleClick = () => {
		onRead()

		const pcId = notification.payload.comment?.positionCandidateId
		if (pcId) {
			store.setSelectedPositionCandidateId(pcId)
			const commentId = notification.payload.comment?.id
			if (commentId) {
				setTimeout(() => {
					const element = document.getElementById(`comment-${commentId}`)
					if (element) {
						element.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
							inline: 'start',
						})
					}
				}, 300)
			}
		}
	}

	return (
		<div
			className="flex flex-row w-full items-center space-x-1 cursor-pointer p-1 hover:bg-neutral-50 hover:cursor-pointer"
			onClick={handleClick}
		>
			<div className="flex px-1 py-3 place-self-start">
				{user ? (
					<OrgUserAvatar user={user} size="xs" />
				) : (
					<HiChatBubbleOvalLeft className="w-6 h-6 text-neutral-600" />
				)}
			</div>
			<div className="flex flex-col w-full p-2 text-neutral-600">
				<div className="text-xs/6 line-clamp-2">
					<div className="flex flex-wrap space-x-1">
						{bold(user?.firstName)}
						{mentioned ? <p>mentioned you on </p> : <p>commented on </p>}
						{candidate && (
							<span>
								{bold(candidate.firstName)} {bold(candidate.lastName)}&apos;s Card
							</span>
						)}
						<p>({bold(position?.referenceName)})</p>
					</div>
				</div>

				<div className="p-2 border rounded-lg bg-neutral-100">
					<span className="flex-1 text-xs/6 line-clamp-[20] break-all max-w-none">
						<div
							dangerouslySetInnerHTML={{
								__html: Autolinker.link(
									linkMentions(comment?.content ?? notification.payload.content)
								)
									.split('\n')
									.join('<br/>'),
							}}
						></div>
					</span>
				</div>
				<p className="text-xs/6 place-self-end pt-1 text-neutral-500">
					{formatTimeDifference(createdAt)}
				</p>
			</div>
		</div>
	)
}

type TaskNotificationProps = {
	notification: INotification
	completed?: boolean
	onRead: () => void
}

function TaskNotification({ notification, completed = false, onRead }: TaskNotificationProps) {
	const router = useRouter()
	const task = useReaction(
		() =>
			notification.payload.task?.id
				? rootStore.tasks.getTaskById(notification.payload.task?.id)
				: undefined,
		100
	)
	const completedAtActivity = useReaction(() => {
		return task?.activity.find((a) => a.type === 'completed')
	}, 100)

	const payload = completedAtActivity?.payload as TaskActivityCompletedPayload | undefined
	const completedAt = completedAtActivity?.ts
	const completedBy = payload?.completedByOrgUserId
		? rootStore.organizationUsers.users[payload.completedByOrgUserId]
		: undefined

	const createdBy = task?.createdByOrgUserId
		? rootStore.organizationUsers.users[task.createdByOrgUserId]
		: undefined

	const createdAt = new Date(notification.createdAt)

	const ts = completed ? completedAt : createdAt
	const user = completed ? completedBy : createdBy

	const isCreatedByTrigger = !user && !!task?.data?.source?.trigger

	const candidate = useReaction(() => {
		return task?.data?.candidateId
			? rootStore.candidates.getCandidateById(task.data.candidateId)
			: undefined
	}, 100)

	const handleClick = () => {
		onRead()

		const taskId = notification.payload.task?.id
		if (taskId) {
			router.push(`/tasks/${taskId}`)
		}
	}

	return (
		<div
			className="flex flex-row w-full items-center space-x-1 cursor-pointer p-1 hover:bg-neutral-50 hover:cursor-pointer"
			onClick={handleClick}
		>
			<div className="flex px-1 py-3 place-self-start">
				{user ? (
					<OrgUserAvatar user={user} size="xs" />
				) : isCreatedByTrigger ? (
					<OrgUserAvatar size={'xs'} text={'T'} />
				) : (
					<HiChatBubbleOvalLeft className="w-6 h-6 text-neutral-600" />
				)}
			</div>

			<div className="flex flex-col w-full p-2 text-neutral-600">
				<div className="text-xs/6 line-clamp-2">
					<div className="flex flex-wrap space-x-1 items-center">
						{completed ? <DoneIcon size={12} /> : <TodoIcon size={12} />}
						{isCreatedByTrigger && bold('Touchpoints')}
						{user && bold(user?.firstName)}
						<p>{completed ? 'completed' : 'created'} task</p>
						{bold(task?.referenceNumber)}
						{candidate && (
							<span>
								for {bold(candidate.firstName)} {bold(candidate.lastName)}
							</span>
						)}
					</div>

					<div className="p-2 border rounded-lg bg-neutral-100">
						<span className="flex-1 text-xs/6 line-clamp-[20] break-all max-w-none">
							<div
								dangerouslySetInnerHTML={{
									__html: Autolinker.link(
										linkMentions(task?.data?.html ?? task?.data?.title ?? '')
									)
										.split('\n')
										.join('<br/>'),
								}}
							></div>
						</span>
					</div>

					<p className="text-xs/6 place-self-end pt-1 text-neutral-500">
						{ts && formatTimeDifference(new Date(ts))}
					</p>
				</div>
			</div>
		</div>
	)
}

function UnknownNotification({ notification }: { notification: INotification }) {
	return (
		<div className="flex flex-row items-center space-x-2">
			<div className="flex p-3">{notification.type} notification</div>
		</div>
	)
}

function NoNotifications() {
	return (
		<div className="flex flex-row items-center justify-center p-5 bg-white w-96">
			<p className="text-sm text-neutral-500">No notifications</p>
		</div>
	)
}
